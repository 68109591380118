@font-face {
  font-family: League-Gothic;
  src: url('../resources/LeagueGothic-Regular.otf');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&display=swap');

.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

:root {
  --headerFont: League-Gothic, Arial, sans-serif;
  --textFont: Inter, Helvetica, Arial, sans-serif;
  --themeWhite: #FFFFFF;
  --mainFontColor: #1E1E1E;
  --mainButtonColor: #1E1E1E;
  --darkBoxColor: #1E1E1E;
  --accentColor: #ED1C24;
  --accentColorHighlight: #7a1014;
  --contentBoxColor: #F1F1F1;
  --contentBoxSubboxHeaderColor: #a8a8a8;
  --contentBoxSubboxColor: #d8d8d8;
  --contentBorderColor: #1E1E1E;
  --smallPullboxColor: #1E1E1E;
  --smallPullboxBorderColor: #000000;
  --pullboxColor: #F1F1F1;
  --pullboxBorderColor: #1E1E1E;
  --disabledButtonColor: #B9B9B9;
  --disabledButtonFontColor: #F1F1F1;
  -webkit-tap-highlight-color: transparent;
}

:root.dark-theme {
  --themeWhite: #F1F1F1;
  --mainFontColor: #F1F1F1;
  --darkBoxColor: #171717;
  --accentColor: #f71d23;
  --contentBoxColor: #171717;
  --contentBorderColor: #353535;
  --contentBoxSubboxHeaderColor: #414141;
  --contentBoxSubboxColor: #6E6E6E;
  --smallPullboxColor: #6B6B6B;
  --smallPullboxBorderColor: #868686;
  --pullboxColor: #6E6E6E;
  --pullboxBorderColor: #A0A0A0;
  --disabledButtonColor: #3A3A3A;
  --disabledButtonFontColor: #B2B2B2;
  --mainButtonColor: #B2343A; /* less sat: #A83E43, more sat: #B82E35 */
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.flip {
  transform: scaleY(-1);
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

a {
  color: var(--mainFontColor);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

body {
  background-image: url('../images/y-so-serious-white.png'); /* Background pattern from Toptal Subtle Patterns */
  background-size: 320px;
  background-repeat: repeat;
  margin: 0;
}

.dark-theme-bg {
  background-image: url('../images/y-so-serious-black.png'); /* Background pattern from Toptal Subtle Patterns */
  background-size: 420px;
  background-repeat: repeat;
}

#root {
  background-size: auto;
  background-repeat: no-repeat;
}

.content-box {
  background: var(--contentBoxColor);
  border: 1.5px solid var(--contentBorderColor);
  border-radius: 5px;
  margin-bottom: 7px;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  max-width: 90vw;
  align-items: center;
  text-align: center;
}

h1 {
  font-size: 56px;
  text-align: center;
  font-family: var(--headerFont);
  color: var(--mainFontColor);
  font-style: normal;
  font-weight: normal;
  padding: 0;
  margin: inherit;
  margin-top: 10px;
  text-decoration: none;
}

h2 {
  font-size: 45px;
  text-align: center;
  font-family: var(--headerFont);
  color: var(--mainFontColor);
  font-style: normal;
  font-weight: normal;
  margin: inherit;
  margin-bottom: 7px;
  text-decoration: none;
}

h3 {
  font-family: var(--textFont);
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: -0.03em;
  color: var(--mainFontColor);
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 15px;
}

h4 {
  font-size: 22px;
  text-align: center;
  font-family: var(--headerFont);
  color: var(--themeWhite);
  font-style: normal;
  font-weight: normal;
  margin: inherit;
  margin-bottom: 6px;
  margin-top: 9px;
  text-decoration: none;
}

h5 {
  font-size: 36px;
  text-align: center;
  font-family: var(--headerFont);
  color: var(--mainFontColor);
  font-style: normal;
  font-weight: normal;
  margin: inherit;
  margin-bottom: 7px;
  text-decoration: none;
}

p {
  font-family: var(--textFont);
  font-size: 14px;
  letter-spacing: -0.03em;
  text-align: center;
  color: var(--mainFontColor);
  font-style: normal;
  font-weight: normal;
  padding: 10px 15px 10px 15px;
  margin: inherit;
  word-spacing: -0.03em;
}

.leftbox p {
  text-align: left;
}

.largetext {
  font-size: 16px;
  letter-spacing: -0.01em;
  word-spacing: -0.01em;
}

.largetext p {
  padding: 0px;
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: -0.01em;
  word-spacing: -0.01em;
}

.largetext-label {
  font-family: var(--textFont);
  color: var(--mainFontColor);
  font-style: normal;
  font-weight: normal;
  padding: 0px;
  font-size: 14px;
  margin-bottom: 1px;
  letter-spacing: -0.01em;
  word-spacing: -0.01em;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.about-content {
  text-align: left;
  max-width: min(1200px, 90vw);
  padding: 10px 15px;
}

.about-h3 {
  margin-left: 0px;
  padding-top: 10px;
}

.in-text-link {
  font-family: var(--textFont);
  color: var(--mainFontColor);
  font-weight: 600;
  text-decoration: underline !important;
  cursor: pointer;
}

.in-text-link:hover {
  color: var(--accentColor);
}

.tooltip {
  position: relative;
}

.dark-theme .tooltip img {
  filter: invert(100%) brightness(95%);
}

.tooltip .tooltip-text {
  visibility: hidden;
  background-color: #1E1E1EF3;
  color: var(--themeWhite);
  font-family: var(--textFont);
  font-size: 13px;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
  font-style: normal;
  font-weight: 300;
  text-align: left;
  border-radius: 4px;
  padding: 7px 7px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  transform: translate(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  margin: 0;
}

.dark-theme .tooltip .tooltip-text {
  background-color: #757575F0;
}

.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.dark-theme .tooltip .tooltip-text::after {
  border-color: #757575 transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text li {
  margin-bottom: 3px;
  position: relative;
  left: 3px;
}

.tooltip-text li span {
  position: relative;
  left: -7px;
  line-height: 12px;
}

.tooltip-link {
    font-style: normal;
    text-decoration: underline !important;
    font-weight: 500;
    color: var(--themeWhite);
}

.tooltip-link:hover {
  color: var(--accentColor);
}

button:hover:enabled {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  /* transition: .05s ease-out; */
}

.black-button {
  background: var(--mainButtonColor);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
  padding: 0px;
  /* transition: .05s ease-out; */
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  height: 30px;
  border: none;
}

.icon-button {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  align-content: space-between;
  justify-content: space-between;
}

.text-icon-button {
  display: inline-flex;
  align-items: left;
  vertical-align: middle;
  text-align: left;
  align-content: space-between;
  justify-content: space-between;
}

.black-button:hover:enabled {
  background: var(--accentColor);
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  /* transition: .05s ease-out; */
}

.black-button:disabled {
  background: var(--disabledButtonColor);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.dark-theme .black-button:disabled img {
  filter: brightness(70%);
}

.black-button span {
  font-family: var(--headerFont);
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  margin-right: 12px;
  margin-left: 12px;
  margin-top: auto;
  margin-bottom: auto;
  
  display: flex;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  
  color: var(--themeWhite);
}

.black-button:disabled span {
  color: var(--disabledButtonFontColor);
}

.black-button img {
  display: inline-block;
}

.white-button {
  background: var(--themeWhite);
  border: #1E1E1E;
  border-width: 2px;
  border-style: solid;
  box-shadow: none;
}

.white-button span {
  color: #1E1E1E;
}

.white-button:hover:enabled span {
  color: var(--themeWhite);
}

.grid-left {
  margin-right: auto;
}

.grid-row1 {
  grid-row-start: 1;
}

.grid-row2 {
  grid-row-start: 2;
}

.grid-middle {
  grid-column-start: 2;
}

.grid-middle-right {
  grid-column-start: 3;
}

.grid-right {
  margin-left: auto;
}

.black-dropdown {
  position: relative;
  display: inline-block;
}

.button-bar-label {
  display: inline-block;
  font-family: var(--headerFont);
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  margin-right: 5px;
  vertical-align: middle;
  color: var(--mainFontColor);
}

.open-bottom-corners {
  border-radius: 4px 4px 0px 0px !important;
}

.black-dropdown-button {
  background: var(--mainButtonColor);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;
  width: fit-content;
  height: fit-content;
  padding: 4px 7px 4px 7px;
  transition: .05s ease-out;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  align-content: space-between;
  justify-content: space-between;
}

.black-dropdown-button:hover:enabled {
  background: var(--accentColor);
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  /* transition: .05s ease-out; */
}

.black-dropdown-button:disabled {
  background: var(--disabledButtonColor);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.dark-theme .black-dropdown-button:disabled img {
  filter: brightness(70%);
}

.black-dropdown-button span {
  font-family: var(--headerFont);
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  
  display: inline-block;
  align-items: center;
  text-align: left;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  
  color: var(--themeWhite);
}

.black-dropdown-button:disabled span {
  color: var(--disabledButtonFontColor);
}

.black-dropdown-arrow {
  display: inline-flex;
  
  align-self: right;
  justify-self: right;
  align-items: right;
  text-align: right;
  vertical-align: middle;
  margin-left: 7px;
}

.black-dropdown ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: var(--mainButtonColor);
  overflow-x: visible;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 0px 0px 3px 3px;
  z-index: 4;
  max-height: 33vh;
}

.black-dropdown ul li {
  color: var(--themeWhite);
  text-decoration: none;
  display: block;
  font-family: var(--headerFont);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  padding: 2px 7px 2px 7px;
  cursor: pointer;
  min-width: max-content;
  text-align: left;
}

.subdropdown ul {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  overflow: auto;
}

.show-subdropdown ul {
  display: block !important;
}

.black-dropdown ul li:hover {
  background-color: #661212;
}

.save-warning {
  display: inline-flex;
  font-family: var(--textFont);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: -0.05em;
  color: var(--mainFontColor);
  position: relative;
  vertical-align: middle;
}

.input-box {
  display: inline-flex;
  height: 30px;
  background: var(--themeWhite);
  box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.25);
  border: .5px solid #cacaca;
  border-radius: 4px;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  align-content: space-between;
  justify-content: space-between;
}

.input-box:focus-within {
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: 0px;
}

.input-box input {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  padding-left: 5px;
  font-family: var(--textFont);
  font-size: 14px;
  letter-spacing: -0.05em;
  height: 100%;
}

.input-box input:focus-visible {
  outline: none;
  outline-offset: initial;
}

.input-box img {
  float: right;
  position: relative;
  right: -1px;
  cursor: pointer;
  margin: 0;
  padding: 6px;
  background-color: var(--mainButtonColor);
  border-radius: 0px 4px 4px 0px;
  border: .5px solid var(--mainButtonColor);
}

.input-box img:hover {
  background-color: var(--accentColor);
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  /* transition: .05s ease-out; */
}

.input-box::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #959595;
  opacity: 1; /* Firefox */
}

.input-box:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #959595;
}

.input-box::-ms-input-placeholder { /* Microsoft Edge */
  color: #959595;
}

.empty-search {
  font-family: var(--textFont);
  font-size: 14px;
  letter-spacing: -0.03em;
  text-align: center;
  color: var(--mainFontColor);
  font-style: normal;
  font-weight: normal;
  margin: auto;
  word-spacing: -0.03em;
  width: 100%;
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
}

.floating-text {
  font-family: var(--textFont);
  font-size: 16px;
  letter-spacing: -0.03em;
  display: inline-flex;
  vertical-align: middle;
  font-weight: 500;
  color: var(--mainFontColor);
}

.checkbox {
  background: var(--themeWhite);
  border-radius: 4px;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.45);
  outline: 1px solid #1E1E1E;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0px;
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
}

.checkbox-disabled {
  background: #B9B9B9;
  box-shadow: none;
  cursor: not-allowed;
}

.label-disabled {
  color: #B9B9B9;
  cursor: not-allowed;
}

.modal {
  display: none;
  justify-content: center;
  position: fixed;
  z-index: 9002;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.dark-theme .modal {
  background-color: rgba(62,62,62,0.56);
}

.modal-content {
  position: relative;
  top: 30%;
  padding: 10px 21px;
  z-index: 9003;
  height: fit-content;
}

.modal-close {
  position: fixed;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
}

.vertical-form-text-input {
  width: 100%;
  margin: auto;
  text-align: right;
  margin-bottom: 7px;
}

.vertical-form-text-input label {
  font-family: var(--textFont);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.05em;
  color: var(--mainFontColor);
  text-align: right;
  margin-right: 7px;
  vertical-align: middle;
}

.text-input {
  display: inline-flex;
  height: 30px;
  background: var(--themeWhite);
  box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.25);
  border: .5px solid #cacaca;
  border-radius: 4px;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  align-content: space-between;
  justify-content: space-between;
}

.para-text-input {
  height: 300px;
  vertical-align: top;
}

.text-input:focus-within {
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: 0px;
}

.text-input input, .text-input textarea {
  border-radius: 4px;
  background-color: var(--themeWhite);
  box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.25);
  border: .5px solid #cacaca;
  border: none;
  margin: 0;
  padding: 0;
  padding-left: 5px;
  font-family: var(--textFont);
  font-size: 14px;
  letter-spacing: -0.05em;
  height: 100%;
}

.para-text-input textarea {
  width: 100%;
}

.text-input input:focus-visible, .text-input textarea:focus-visible {
  outline: none;
  outline-offset: initial;
}

.middletext-divider {
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  justify-items: center;
  align-items: center;
  vertical-align: middle;
  margin: auto;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 7px;
}

.gray-line {
  width: 100%;
  height: 0px;
  border-top: 1.5px solid #A4A4A4;
}

.gray-middle-text {
  display: flex;
  font-family: var(--headerFont);
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  color: #A4A4A4;
  text-align: center;
  align-items: middle;
  vertical-align: middle;
}

.black-underline-link {
  font-family: var(--headerFont);
  font-weight: normal;
  text-decoration: underline;
  font-size: 20px;
  color: var(--mainFontColor);
  cursor: pointer;
  text-align: center;
  vertical-align: center;
}

.black-underline-link:hover {
  color: var(--accentColor);
}

.validation-error {
  display: block;
  margin: auto;
  font-family: var(--textFont);
  font-size: 13px;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
  font-style: italic;
  font-weight: 500;
  color: #FF0000;
  max-width: 275px;
}

.dark-theme .validation-error {
  color: #FFBEBE;
}

.hover-card-div {
  position: relative; /* Need this for absolute positioning of add-on elements to work */
}

.hover-card-div:hover {
  border-top-style: hidden;
}

.card-hover {
  position: initial;
  display: flex;
  background-color: #1E1E1EF3;
  border-radius: 4px;
  width: max-content;
  height: initial;
  padding: 15px;
  vertical-align: top;
  z-index: 9001;
  transform: none;
  transition: none;
  transform-style: initial;
  text-align: left;
  perspective: initial;
  cursor: initial;
  margin: initial;
}

.dark-theme .card-hover {
  background-color: #444444f3;
}

.card-hover-right {
  display: inline-block;
  flex-wrap: wrap;
  max-width: min(450px, 63vw);
  margin-right: 10px;
}

.card-hover-left {
  display: inline-block;
}

.card-hover-right .line-div {
  display: flex;
  margin-bottom: 10px;
  vertical-align: middle;
}

.card-hover-right .sameline-div {
  display: inline-flex;
  margin-right: 30px;
  vertical-align: middle;
}

.card-hover-right img {
  display: inline-flex;
  margin-right: 5px;
  vertical-align: middle;
}

.card-hover span {
  display: inline-flex;
  font-family: var(--textFont);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: -0.04em;
  text-align: left;
  vertical-align: middle;
  margin-left: initial;
  margin-right: initial;
  margin-top: auto;
  margin-bottom: auto;
  filter: initial;
  transition: initial;
  
  color: var(--themeWhite);
}

.dark-theme .card-hover span {
  color: #F9F9F9;
}

.card-hover h4 {
  font-size: 26px;
  letter-spacing: 0.018em;
  text-align: left;
  margin-bottom: auto;
  margin-top: auto;
  text-decoration: none;
}

span.replacement-card-title {
  display: inline;
  position: absolute;
  left: 7%;
  top: 4.5%;
  height: 7%;
  width: 86%;
  text-align: left;
  margin: 0;
  font-size: 12px;
  color: #000;
  font-family: var(--textFont);
  font-weight: 600;
  filter: none;
  transition: none;
  overflow: hidden;
  letter-spacing: -0.01em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.black-toggle {
  position: relative;
  display: inline-block;
  background: var(--mainButtonColor);
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
  padding: 0px;
  transition: .05s ease-out;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  align-content: space-between;
  justify-content: space-between;
  margin-right: 10px;
}

.black-toggle-option {
  display: inline-block;
  background: var(--themeWhite);
  color: #1E1E1E;
  border: none;
  width: fit-content;
  height: fit-content;
  margin: 0px;
  transition: .05s ease-out;
  cursor: pointer;
  border-radius: 4px;
  padding-top: 2px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  align-content: center;
  justify-content: center;
}

.right-toggle-option {
  border-radius: 0px 4px 4px 0px;
  border: 1pt solid #1E1E1E;
  margin-left: -1pt;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.middle-toggle-option {
  border: 1pt solid #1E1E1E;
  border-radius: 0px;
  margin-left: -1pt;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.left-toggle-option {
  border-radius: 4px 0px 0px 4px;
  border: 1pt solid #1E1E1E;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.black-toggle:disabled {
  background: #B9B9B9;
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.black-toggle-option span {
  font-family: var(--headerFont);
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  margin: auto;
  
  display: inline-flex;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.toggle-on {
  background: var(--mainButtonColor);
  box-shadow: none;
  z-index: 1;
}

.dark-theme .toggle-on {
  border: 1pt solid var(--mainButtonColor);
}

.toggle-on span {
  color: var(--themeWhite);
}

.black-toggle-option:hover {
  border-color: var(--accentColor);
  color: var(--accentColor);
  z-index: 2;
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
  /* transition: .05s ease-out; */
}

.toggle-on:hover {
  border-color: var(--accentColor);
  background: var(--accentColor);
}

.account-divider {
  width: 85%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  height: 0px;
  border-top: 1.5px solid #A4A4A4;
}

.forgot-pass-button {
  color: var(--mainFontColor);
  font-family: var(--textFont);
  font-size: 12px;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  text-align: right;
  margin-left: auto;
  width: fit-content;
}

.forgot-pass-button:hover {
  color: var(--accentColor);
}

.verified {
  display: inline-flex;
  color: #00a005;
  font-family: var(--textFont);
  font-size: 12px;
  margin: 0px;
  margin-left: 2px;
  padding: 0px;
  padding-top: 2px;
  font-style: italic;
  text-align: right;
  vertical-align: middle;
}

.verified-checkmark {
  display: inline-block;
  vertical-align: middle;
}

.lazy-image-container {
  background-color: #1e1e1e0C;
}

.lf-icon {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  z-index: 3;
}

.clipboard-popup {
  position: absolute;
  top: 5vh;
  left: 50%;
  transform: translate(-50%);
  background: #1E1E1EF3;
  padding: 10px;
  border-radius: 4px;
  visibility: hidden;
  z-index: 10;
}

.dark-theme .clipboard-popup {
  background: #757575F0;
}

.clipboard-popup span {
  color: var(--themeWhite);
  font-family: var(--textFont);
  font-size: 17px;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
}

input.page-entry::-webkit-outer-spin-button,
input.page-entry::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number].page-entry {
  -moz-appearance: textfield;
}

.page-entry-field {
  font-family: var(--textFont);
  font-size: 16px;
  letter-spacing: -0.03em;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  font-weight: 500;
  color: var(--mainFontColor);
  height: 22px;
  margin-right: 2px;
}

.page-entry-field input {
  height: 20px;
  width: 38px;
  background: var(--themeWhite);
  box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.25);
  border: .5px solid #1E1E1E;
  border-radius: 3px;
  font-family: var(--textFont);
  font-size: 16px;
  letter-spacing: -0.03em;
  text-align: right;
}

@media (max-width: 1300px) {
  h1 {
    font-size: 56px;
    margin-top: 20px;
  }

  .modal-content {
    top: 20%;
  }
}

@media (max-width: 1150px) {
  p {
    font-size: 13px;
  }

  .largetext {
    font-size: 13px;
    letter-spacing: initial;
    word-spacing: initial;
  }

  .largetext p {
    font-size: 13px;
    letter-spacing: initial;
    word-spacing: initial;
  }

  h1 {
    font-size: 45px;
    margin-top: 10px;
  }
  
  h2 {
    font-size: 36px;
    margin-bottom: 5px;
  }
  
  h3 {
    font-size: 18px;
    margin-bottom: 3px;
  }
  
  h4 {
    font-size: 18px;
    text-align: center;
    font-family: var(--headerFont);
    color: var(--themeWhite);
    font-style: normal;
    font-weight: normal;
    margin: inherit;
    margin-bottom: 6px;
    margin-top: 9px;
    text-decoration: none;
  }
  
  h5 {
    font-size: 36px;
    text-align: center;
    font-family: var(--headerFont);
    color: var(--mainFontColor);
    font-style: normal;
    font-weight: normal;
    margin: inherit;
    margin-bottom: 7px;
    text-decoration: none;
  }
}

@media (max-width: 950px) {
  p {
    padding: 3px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 3px;
    margin-left: 5px;
  }

  .vertical-form-text-input label {
    font-size: 14px;
    letter-spacing: -0.05em;
  }

  .vertical-form-text-input .text-input input {
    width: 120px;
  }
  
  .black-button {
    background: var(--mainButtonColor);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: fit-content;
    height: fit-content;
    padding: 0px;
    /* transition: .05s ease-out; */
    cursor: pointer;
    display: inline-flex;
    vertical-align: middle;
    height: fit-content;
    min-height: 26px;;
    border: none;
  }
  
  .icon-button {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    text-align: left;
    align-content: space-between;
    justify-content: space-between;
  }
  
  .text-icon-button {
    display: inline-flex;
    align-items: left;
    vertical-align: middle;
    text-align: left;
    align-content: space-between;
    justify-content: space-between;
  }
  
  .black-button:hover:enabled {
    background: var(--accentColor);
  }
  
  .black-button span {
    font-size: 16px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    
    display: flex;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    
    color: var(--themeWhite);
  }
  
  .black-button img {
    display: inline-block;
  }
  
  .black-dropdown-button {
    width: fit-content;
    height: fit-content;
    padding: 4px 7px 4px 7px;

    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    text-align: left;
    align-content: space-between;
    justify-content: space-between;
  }

  .black-dropdown-button:hover:enabled {
    background: var(--accentColor);
  }

  .black-dropdown-button:disabled {
    background: var(--disabledButtonColor);
    box-shadow: none;
    border: none;
    cursor: not-allowed;
  }

  .black-dropdown-button span {
    font-size: 16px;
    
    display: inline-block;
    align-items: center;
    text-align: left;
    vertical-align: middle;
    width: 100%;
  }

  .black-dropdown-arrow {
    display: inline-flex;
    
    align-self: right;
    justify-self: right;
    align-items: right;
    text-align: right;
    vertical-align: middle;
    margin-left: 7px;
  }

  .black-dropdown ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    background-color: var(--mainButtonColor);
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 0px 0px 3px 3px;
    z-index: 4;
  }

  .black-dropdown ul li {
    color: var(--themeWhite);
    text-decoration: none;
    display: block;
    font-family: var(--headerFont);
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    padding: 2px 5px 2px 5px;
    cursor: pointer;
    min-width: max-content;
    text-align: left;
  }

  .button-bar-label {
    display: inline-block;
    font-family: var(--headerFont);
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    margin-right: 5px;
    vertical-align: middle;
    color: var(--mainFontColor);
  }

  .card-hover {
    padding: 10px;
  }

  .card-hover span {
    font-size: 13px;;
  }
  
  .card-hover-right .line-div {
    margin-bottom: 5px;
  }

  .card-hover-right .sameline-div {
    margin-right: 15px;
  }
  
  .empty-search {
    font-size: 14px;
    letter-spacing: -0.03em;
    word-spacing: -0.03em;
    width: 100%;
    margin-bottom: 5px;
  }
  
  .floating-text {
    font-size: 14px;
    letter-spacing: -0.03em;
  }

  .checkbox {
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0px;
  }

  .input-box {
    height: 30px;
  }

  .input-box input {
    margin: 0;
    padding: 0;
    padding-left: 5px;
    font-size: 14px;
    letter-spacing: -0.05em;
    height: 100%;
  }

  .input-box input:focus-visible {
    outline: none;
    outline-offset: initial;
  }

  .input-box img {
    right: -1px;
    margin: 0;
    padding: 6px;
  }
}
