#header {
  display: flex;
  width: 100%;
  height: 75px;
  background: linear-gradient(180deg, #303030 0%, #121212 41.67%, #121212 49.48%, #121212 57.29%, #303030 100%); /* 2 way horizontal linear black/dark gray gradient */
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 8000;
}

.dark-theme #header {
  background: linear-gradient(180deg, #141414 0%, #090909 41.67%, #090909 49.48%, #090909 57.29%, #141414 100%);
}

.nav {
  margin-left: 15px;
  float: left;
  display: block;
  height: 75px;
}

.nav ul:after {
  content: "";
  border-right: 1px solid #070707;
  height: 75px;
  position: absolute;
  top: 0;
  box-sizing: border-box;
}

#header:after {
  content: "";
  height: 75px;
  border-right: 1px solid #282828;
  box-sizing: border-box;
  position: relative;
  right: -1px;
  float: left;
}

.nav ul {
  list-style: none;
  display: table;
  margin: 0;
  padding: 0;
  height: 75px;
}

.nav li {
  display: table-cell;
  height: 75px;
}

.nav li:hover .topbar-link {
  background: linear-gradient(180deg, #282828 0%, #111111 41.67%, #111111 49.48%, #111111 57.29%, #282828 100%);
  mix-blend-mode: normal;
  box-shadow: inset 7px 7px 9px rgba(0, 0, 0, 0.75);
}

.dark-theme .nav li:hover .topbar-link {
  background: linear-gradient(180deg, #121212 0%, #080808 41.67%, #080808 49.48%, #080808 57.29%, #121212 100%);
}

.active-page {
  background: linear-gradient(180deg, #282828 0%, #111111 41.67%, #111111 49.48%, #111111 57.29%, #282828 100%);
  mix-blend-mode: normal;
  box-shadow: inset 7px 7px 9px rgba(0, 0, 0, 0.75);
}

.dark-theme .active-page {
  background: linear-gradient(180deg, #121212 0%, #080808 41.67%, #080808 49.48%, #080808 57.29%, #121212 100%);
}

.active-page span {
  border-color: #070707 !important;
}

.nav li .topbar-link {
  cursor: pointer;
  border-left: 1px solid #070707;
  display: block;
  height: 75px;
  text-decoration: none;
  width: 132px;
  box-sizing: border-box;
}

.nav li:hover .topbar-link span {
  border-color: #070707;
}

.nav li .topbar-link span {
  border-left: 1px solid #282828;
  text-align: center;
  font-family: var(--headerFont);
  line-height: 26px;
  font-size: 26px;
  color: #CACACB;
  display: table-cell;
  vertical-align: middle;
  height: 75px;
  width: 131px;
  max-width: 131px;
  box-sizing: border-box;
}

#large-header-right {
  position: absolute;
  right: 0px;
  top: 0;
}

#account-nav-dropdown-box {
  display: table-cell;
}

#packsim-nav-dropdown-box {
  position: relative;
}

div.nav-dropdown-box:hover li .topbar-link {
  background: linear-gradient(180deg, #282828 0%, #111111 41.67%, #111111 49.48%, #111111 57.29%, #111111 100%);
  mix-blend-mode: normal;
  box-shadow: inset 7px 7px 9px rgba(0, 0, 0, 0.75);
}

.dark-theme div.nav-dropdown-box:hover li .topbar-link {
  background: linear-gradient(180deg, #121212 0%, #080808 41.67%, #080808 49.48%, #080808 57.29%, #121212 100%);
}

div.nav-dropdown-box:hover li .topbar-link span {
  border-color: #070707;
}

div.nav-dropdown-box ul {
  display: none;
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 0;
  background: #111111;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 0px 0px 4px 4px;
  z-index: 1;
  width: 132px;
  height: fit-content;
}

div.nav-dropdown-box ul:after {
  content: none;
  border-right: none;
  height: 0;
}

div.nav-dropdown-box ul li {
  text-decoration: none;
  display: block;
  text-align: center;
  font-family: var(--headerFont);
  font-size: 26px;
  color: #CACACB;
  padding: 15px 7px 15px 7px;
  cursor: pointer;
  min-width: max-content;
  height: 30px;
}

.nav li span {
  color: #CACACB;
}

div.nav-dropdown-box ul li:hover {
  background-color: var(--accentColor);
  color: #F1F1F1;
}

div.nav-dropdown-box ul li:hover span {
  color: #F1F1F1;
}

.logo {
  float: left;
  width: 180px;
  height: 44px;
  margin-left: 12px;
  margin-top: 13px;
  background-image: url('../images/YGOprog.png');
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1300px) {
  #header {
    height: 45px;
  }

  .nav {
    height: 45px;
  }

  .logo {
    width: 120px;
    height: 30px;
    margin-left: 7px;
    margin-top: 7px;
  }
  .nav ul:after {
    height: 45px;
  }
  
  #header:after {
    height: 45px;
  }
  
  .nav ul {
    height: 45px;
  }
  
  .nav li {
    height: 45px;
  }
  
  .nav li .topbar-link {
    height: 45px;
    width: 112px;
  }
  
  .nav li .topbar-link span {
    line-height: 22px;
    font-size: 22px;
    height: 45px;
    width: 111px;
    max-width: 111px;
  }
  
  div.nav-dropdown-box ul {
    width: 112px;
  }
  
  div.nav-dropdown-box ul li {
    font-size: 22px;
    color: #CACACB;
    padding: 7px 7px 7px 7px;
    cursor: pointer;
    min-width: max-content;
    height: 27px;
  }
}

@media (hover: none), (max-width: 950px) {
  #header {
    position: sticky;
    top: 0;
    transition: top 0.2s ease-in-out;
  }
}

@media (hover: none) {
  #header.hide-header {
    top: -80px;
  }
}

@media (hover: none) and (max-width: 1300px) {
  #header.hide-header {
    top: -50px;
  }
}

@media (max-width: 950px) { /* Small screens */
  #header.hide-header {
    top: -50px;
  }

  .logo {
    width: 120px;
    height: 30px;
    margin: 7px auto 0px auto;
  }

  #header:after {
    content: none;
  }
  
  #header:after {
    content: none;
  }

  .nav {
    margin: 0;
  }

  .nav img {
    cursor: pointer;
  }
  
  .nav ul {
    display: none;
    position: absolute;
    top: 45px;
    right: 0;
    margin: 0;
    padding: 0;
    background: #111111;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 0px 0px 4px 4px;
    z-index: 1;
    width: 110px;
    height: fit-content;
  }
  
  .nav li {
    display: block;
    height: 45px;
  }
  
  .nav li:hover .topbar-link {
    background: linear-gradient(180deg, #282828 0%, #111111 41.67%, #111111 49.48%, #111111 57.29%, #282828 100%);
    mix-blend-mode: normal;
    box-shadow: inset 7px 7px 9px rgba(0, 0, 0, 0.75);
  }
  
  .dark-theme .nav li:hover .topbar-link {
    background: linear-gradient(180deg, #121212 0%, #080808 41.67%, #080808 49.48%, #080808 57.29%, #121212 100%);
  }
  
  .active-page {
    background: initial;
    mix-blend-mode: initial;
    box-shadow: initial;
  }
  
  .active-page span {
    border-color: #070707 !important;
  }
  
  .nav li .topbar-link {
    cursor: pointer;
    border-left: none;
    display: block;
    height: 45px;
    text-decoration: none;
    width: 110px;
    box-sizing: border-box;
  }
  
  .nav li .topbar-link span {
    border-left: none;
    line-height: 22px;
    font-size: 22px;
    color: #CACACB;
    display: table-cell;
    vertical-align: middle;
    height: 45px;
    width: 110px;
    max-width: 110px;
    box-sizing: border-box;
    text-align: left;
    padding-left: 7px;
  }

  #nav2 ul {
    min-width: min(max-content, 250px);
    width: min(max-content, 250px);
  }
  
  #nav2 ul li {
    text-decoration: none;
    display: block;
    text-align: right;
    font-family: var(--headerFont);
    font-size: 22px;
    color: #CACACB;
    padding: 7px 7px 7px 10px;
    margin-left: auto;
    cursor: pointer;
    min-width: min(max-content, 250px);
    width: min(max-content, 250px);
    height: 27px;
  }
}