.all-cards-search-controls {
  display: grid;
  grid-template-columns: 7fr repeat(1, auto) 3fr;
  justify-items: center;
  margin: auto;
  max-width: min(1300px, 96vw);
  height: fit-content; 
  align-items: center;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 7px;
}

.pagination-controls span {
  font-family: var(--textFont);
  font-size: 16px;
  letter-spacing: -0.03em;
  display: inline-flex;
  vertical-align: middle;
  font-weight: 500;
  color: var(--mainFontColor);
}

.pagination-controls button {
  border-radius: 50%;
  background-color: var(--mainButtonColor);
  padding: 6px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
  border: none;
}

.pagination-controls button:hover:enabled {
  background-color: var(--accentColor);
}

.pagination-controls button:disabled {
  background: var(--disabledButtonColor);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.dark-theme .pagination-controls button:disabled img {
  filter: brightness(40%);
}

.all-cards-search-container {
  padding: 15px;
  padding-bottom: 5px;
  display: block;
  max-width: min(1270px, calc(96vw - 30px));
  width: auto;
}

.binder-controls {
  display: grid;
  grid-template-columns: 7fr repeat(1, auto) 3fr;
  justify-items: center;
  margin: auto;
  max-width: min(1300px, 96vw);
  height: fit-content; 
  align-items: center;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 7px;
}

div.binder-controls.binder-controls-bottom {
  grid-template-columns: 4fr repeat(1, auto) 4fr;
}

.binder-search-container {
  padding: 15px;
  padding-bottom: 5px;
  display: block;
  max-width: min(1270px, calc(96vw - 30px));
  width: auto;
}

.all-search-card {
  display: grid;
  grid-template-columns: 6fr 1fr;
  margin-bottom: 10px;
}

.all-search-card > * {
  display: inline-flex;
  vertical-align: middle;
}

.all-search-card > * > * {
  display: inline-flex;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.all-search-add-button {
  border-radius: 50%;
  background-color: var(--mainButtonColor);
  padding: 6px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  width: fit-content;
  height: fit-content;
}

.all-search-add-button:hover:enabled {
  background-color: var(--accentColor);
}

.all-search-add-button:disabled {
  background: var(--disabledButtonColor);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.dark-theme .all-search-add-button:disabled img {
  filter: brightness(40%);
}

.all-search-card > * > h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
}

.all-search-card > * > * > .black-dropdown-button {
  box-shadow: none;
}

.binder-search-card {
  display: grid;
  grid-template-columns: 5fr 3fr 2fr 1fr;
  margin-bottom: 10px;
}

.binder-search-card > * {
  display: inline-flex;
  vertical-align: middle;
}

.binder-search-card > * > * {
  display: inline-flex;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.binder-search-quantity-button {
  border-radius: 50%;
  background-color: var(--mainButtonColor);
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  width: fit-content;
  height: fit-content;
}

.binder-search-quantity-button:hover:enabled {
  background-color: var(--accentColor);
}

.binder-search-quantity-button:disabled {
  background: var(--disabledButtonColor);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.binder-search-card > * > h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
}

.binder-search-card > * > h4 {
  font-size: 16px;
  font-family: var(--textFont);
  color: var(--mainFontColor);
  font-weight: 500;
  text-align: left;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
}

.binder-rarity-text {
  font-size: 16px;
  font-family: var(--textFont);
  color: var(--mainFontColor);
  font-weight: 300;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
}

.binder-card-count {
  font-size: 15px;
  font-family: var(--textFont);
  text-align: center;
  color: var(--mainFontColor);
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 40px;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
  align-content: center;
  justify-content: center;
}

.results-per-top {
  margin-right: 7px;
}

@media (max-width: 1300px) {
  .all-card-controls {
    grid-template-columns: 6fr repeat(1, auto) 3fr;
  }

  .binder-controls {
    grid-template-columns: 4fr repeat(1, auto) 1fr;
  }

  .results-per-top {
    margin-right: 0px;
  }

  div.pagination-top {
    text-align: right;
    display: flex;
    justify-self: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
  }
}

@media (max-width: 950px) {
  .pagination-controls span {
    font-size: 14px;
    letter-spacing: -0.03em;
  }

  .pagination-controls button {
    padding: 6px;
    margin-left: 3px;
  }

  .all-cards-search-container {
    padding: 5px;
    max-width: calc(99vw - 10px);
  }

  .all-card-controls {
    grid-template-columns: 7fr repeat(1, auto) 3fr;
    margin-bottom: 5px;
    max-width: 99vw;
  }

  .binder-controls {
    grid-template-columns: 3fr repeat(1, auto) 2fr;
    margin-bottom: 5px;
    max-width: 99vw;
  }
  
  .binder-controls-bottom > div {
    display: flex;
    justify-self: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
  }

  .binder-search-container {
    padding: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    max-width: calc(99vw - 10px);
  }

  .all-cards-search-controls {
    grid-template-columns: 3fr repeat(1, auto) 2fr;
    margin-bottom: 5px;
    max-width: 99vw;
  }
  
  .all-search-card {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 5px;
  }

  .all-search-card > * {
    display: inline-flex;
    vertical-align: middle;
  }

  .all-search-card > * > * {
    display: inline-flex;
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }

  .all-search-card > * > * > h3 {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 0;
    letter-spacing: -0.03em;
    word-spacing: -0.03em;
    margin-left: 0px;
    line-height: 1;
  }

  .all-search-card-right-small {
    display: flex;
    justify-self: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: calc(96vw - 95px);
  }

  .all-search-add-button {
    padding: 6px;
  }

  div.all-search-card .card-image {
    height: 70px;
  }

  div.binder-search-card .card-image {
    height: 70px;
  }
  
  .binder-search-card {
    display: grid;
    grid-template-columns: 5fr 1fr;
    margin-bottom: 5px;
  }

  .binder-search-card > * {
    display: inline-flex;
    vertical-align: middle;
  }

  .binder-search-card > * > * {
    display: inline-flex;
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }

  .binder-search-quantity-button {
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    width: fit-content;
    height: fit-content;
  }

  div.binder-search-card h3 {
    font-size: 17px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 3px;
    letter-spacing: -0.03em;
    word-spacing: -0.03em;
  }

  div.binder-search-card h4 {
    font-size: 14px;
    font-family: var(--textFont);
    color: var(--mainFontColor);
    font-weight: 500;
    text-align: left;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: -0.03em;
    word-spacing: -0.03em;
  }

  .binder-rarity-text {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: -0.03em;
    word-spacing: -0.03em;
  }
  
  .binder-search-card-middle-small {
    display: flex;
    justify-self: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-right: 5px;
  }

  .binder-card-count {
    font-size: 13px;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 40px;
    letter-spacing: -0.03em;
    word-spacing: -0.03em;
    align-content: center;
    justify-content: center;
  }

}