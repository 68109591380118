.card-pulls-container {
  display: block;
  padding: 18px 0px 18px 0px;
  max-width: min(96vw, 1018.25px);
  width: fit-content;
}

.pull-card {
  display: inline-block;
  margin: 18px;
  background-color: transparent;
  width: 160.45px;
  height: 250.67px;
  perspective: 1000px;
  cursor: pointer;
}

.pull-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.7s ease-out;
  transform-style: preserve-3d;
}

.pull-card-front, .pull-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.pull-card-front {
  transform: rotateY(180deg);
}

.card-image {
  display: block;
  background-color: #1e1e1e0C;
  font-size: 0px;
}

.dark-theme .card-image {
  filter: saturate(98%) brightness(93%);
}

.pull-card span {
  display: block;
  margin: auto;
  text-align: center;
  font-family: var(--textFont);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: var(--mainFontColor);
  filter:blur(3px);
  transition: 0.5s ease-out;
}

span.replacement-card-title {
  display: inline;
  position: absolute;
  left: 7%;
  top: 4.5%;
  height: 7%;
  width: 86%;
  text-align: left;
  margin: 0;
  font-size: 12px;
  color: #000;
  font-family: var(--textFont);
  font-weight: 600;
  filter: none;
  transition: none;
  overflow: hidden;
  letter-spacing: -0.01em;
  white-space: nowrap;
  text-overflow: ellipsis;
  filter: none;
}

/* Rare shine element */
.rare-effect:after { 
  
  animation: shine 3.5s ease-in-out infinite;
  animation-fill-mode: forwards;  
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 250%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);
  
  background: rgb(255,255,255);
  background: linear-gradient(to right, 
  rgba(255,255,255,0) 0%, 
  rgba(255,255,255,0) 84%, 
  rgba(255,255,255,0.3) 88%, 
  rgba(255,255,255,0.7488039160976891) 92%, 
  rgba(255,255,255,0.3) 96%, 
  rgba(255,255,255,0) 100%);
}

.rare-effect:active:after {
  opacity: 0;
}

@keyframes shine{
  0% {
    opacity: 0;
    top: -110%;
    left: -210%;
    transition-property: left, top, opacity;
  }
  10% {
    opacity: .7;
    transition-property: opacity;
  }
  20% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

.card-link {
  width: 100%;
  height: 100%;
}

.card-pulls-button-container {
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  justify-items: center;
  margin: auto;
  max-width:  min(96vw, 1018.25px);
  height: fit-content; 
  vertical-align: middle;
}

.pack-odds-link {
  padding-top: 8px;
  font-family: var(--textFont);
  font-style: italic;
  font-weight: 400;
  font-size: 13.5px;
  text-decoration: underline !important;
  color: var(--mainFontColor);
}

.total-pulls-button-container {
  display: grid;
  grid-template-columns: 9fr  3fr;
  justify-items: left;
  height: fit-content; 
  margin: auto;
  margin-bottom: 7px;
  max-width:  min(96vw, 1350px);
  align-items: left;
  vertical-align: middle;
}

.total-pulls-container {
  display: block;
  padding: 15px 0px 15px 0px;
  max-width: min(96vw, 1350px);
  width: 100%;
}

.total-pulls-group-bin {
  display: flex;
  align-items: left;
  gap: 5px;
  text-align: left;
  max-width: 100%;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.total-pulls-card {
  display: inline-flex;
  width: 137.14px;
  height: 200px;
  position: relative;
  cursor: pointer;
}

.total-pulls-card-count {
  position: absolute;
  display: flex;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  min-width: 36.67px;
  width: fit-content;
  height: 36.67px;
  background: #1E1E1E;
  border-radius: 4px 0px 0px 0px;
  align-items: center;
}

.total-pulls-card-count span {
  font-family: var(--textFont);
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: var(--themeWhite);
  margin: auto;
  padding-left: 4px;
  padding-right: 4px;
}

.draft-card-add-button {
  border-radius: 4px;
  background-color: var(--mainButtonColor);
  padding: 5px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  width: fit-content;
  height: fit-content;
}

.flip-rarity-text:hover .draft-card-add-button:enabled {
  background-color: var(--accentColor);
}

.draft-card-add-button:disabled {
  background: var(--disabledButtonColor);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.draft-card-undo-button {
  border-radius: 50%;
  background-color: var(--accentColorHighlight);
  padding: 5px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  width: fit-content;
  height: fit-content;
}

.flip-rarity-text:hover .draft-card-undo-button:enabled {
  background-color: var(--accentColor);
}

.draft-card-undo-button:disabled {
  background: var(--disabledButtonColor);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
}

.flip-rarity-text {
  line-height: initial;
}

@media (max-width: 1300px) {
  .total-pulls-button-container {
    grid-template-columns: 9fr  5fr;
  }
}

@media (max-width: 1150px) {
  div.total-pulls-button-container div.grid-right {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
  
  div.total-pulls-button-container div.grid-right button {
    margin-right: 0px;
    margin-left: 3px;
    display: inline-flex;
    vertical-align: bottom;
    margin-top: auto;
  }
}

@media (max-width: 950px) {
  .card-pulls-container {
    padding: 5px 0px;
    width: fit-content;
  }
  
  .pull-card {
    margin: 5px;
    width: 91.88px;
    height: 154.67px;
    perspective: 1000px;
  }
  
  .pull-card span {
    font-size: 13px;
    letter-spacing: -0.03em;
  }

  .card-image {
    height: 134px;
  }

  div.total-pulls-card .card-image {
    height: 100px;
  }

  .all-search-add-button {
    padding: 6px;
  }

  .card-pulls-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    margin: auto;
    height: fit-content; 
    vertical-align: middle;
  }

  div.card-pulls-button-container button {
    margin-bottom: 3px;
  }
  
  .pack-odds-link {
    padding-top: 8px;
    font-size: 12px;
    width: 100%;
    text-align: center;
    display: inline-flex;
  }
  
  .total-pulls-button-container {
    grid-template-columns: 9fr  3fr;
    margin-bottom: 3px;
    padding: 0px;
  }

  div.total-pulls-button-container button {
    margin-bottom: 3px;
  }
  
  .total-pulls-container {
    display: block;
    padding: 5px 0px;
    width: 100%;
  }
  
  .total-pulls-group-bin {
    gap: 3px;
    margin-left: 5px;
    margin-right: 3px;
    margin-bottom: 5px;
  }
  
  .total-pulls-card {
    width: 68.57px;
    height: 100px;
  }
  
  .total-pulls-card-count {
    z-index: 1;
    min-width: 30px;
    width: fit-content;
    height: 30px;
  }
  
  .total-pulls-card-count span {
    font-size: 20px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .black-dropdown ul {
    margin-top: -3px;
  }
}