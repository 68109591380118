.packs-container {
  padding: 25px;
  display: block;
  max-width: min(1300px, calc(96vw - 50px));
  width: auto;
}

.cardset-box {
  display: inline-block;
  background-image: url('../images/dark-honeycomb.png'); /* Background pattern from Toptal Subtle Patterns */
  background-repeat: repeat;
  background-size: 320px;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.35));
  border-radius: 5px;
  margin: 5px;
  width: 200px;
  height: 282px;
}

.dark-theme .cardset-box {
  background-image: url('../images/light-honeycomb.png');
}

.cardset-link {
  height: 236px;
}

.cardset-image {
  display: block;
  overflow: visible;
  margin: auto;
  padding-top: auto;
  padding-bottom: auto;
  min-width: 110px;
  max-width: 170px;
}

.dark-theme .cardset-image {
  filter: saturate(97%) brightness(92%);
}

.pack-num-entry {
  width: 110px;
  margin: auto;
  margin-top: 7px;
  height: 25px;
}

.pack-num-entry label {
  font-family: var(--textFont);
  font-size: 14px;
  text-align: left;
  color: var(--themeWhite);
  font-style: normal;
  font-weight: bold;
  word-spacing: -0.05em;
  height: 25px;
}

.pack-num-entry label input {
  height: 16px;
  width: 38px;
  background: var(--themeWhite);
  box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 1px;
  font-family: var(--textFont);
  font-size: 13px;
  letter-spacing: -0.05em;
  margin-left: 10px;
}

#open-packs-tray {
  width: 250px;
  padding-right: 5px;
  height: 400px;
  background: var(--smallPullboxColor);
  position: fixed;
  bottom: 20px;
  border-radius: 5px;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--smallPullboxBorderColor);
  transition: right 0.2s ease-out;
  z-index: 1;
}

#collapse-tray-button {
  margin: inherit;
  background: none;
  padding: 0;
  margin-top: 5px;
  margin-left: 5px;
  border: none;
  float: left;
  cursor: pointer;
}

#collapse-tray-button:hover {
  filter: brightness(85%);
}

#open-packs-tray-title {
  font-family: var(--textFont);
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  letter-spacing: -0.035em;
  color: var(--themeWhite);
  margin-left: 10px;
  margin-top: 8.5px;
  float: left;
}

#open-packs-tray p {
  font-family: var(--textFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: left; 
  margin: 0;
  padding: 0px 0px 3px 0px;
  letter-spacing: -0.035em;
  color: var(--themeWhite);
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: visible;
  text-overflow: ellipsis;
}

#packstoopen-scroll {
  margin: 0;
  margin-left: 5px;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  height: 322px;
  width: 240px;
}

#packstoopen-scroll::-webkit-scrollbar {
  width: 5px;
}

#packstoopen-scroll::-webkit-scrollbar-track {
  background: #555555;
  border-radius: 8px;
}

#packstoopen-scroll::-webkit-scrollbar-thumb {
  background: #F1F1F1; 
  border-radius: 8px;
  width: 3px;
}

#open-packs-button {
  margin: 5px 5px 5px 5px;
  width: 150px;
  height: 31px;
  background: var(--accentColor);
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  padding: 0;
  cursor: pointer;
}

#open-packs-button:hover:enabled {
  background: #cf151b;
}

#open-packs-button:disabled {
  background: #70090d;
  cursor: not-allowed;
}

#open-packs-button:disabled img {
  filter: brightness(50%);
}

#open-packs-button:disabled span {
  filter: brightness(50%);
}

#open-packs-button img {
  float: left;
  padding: 3px 3px 3px 6px;
  /* border-right: 2px solid var(--themeColor); */
}

#open-packs-button span {
  float: left;
  font-family: var(--textFont);
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  width: 102px;
  padding: 4px 4px 4px 4px;

  color: var(--themeWhite);
}

.pack-select-box {
  cursor: pointer;
  transition: .25s ease-out;
}

.pack-select-box:hover {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
  transition: .25s ease-out;
}

.pack-select-count {
  font-family: var(--textFont);
  font-size: 14px;
  text-align: center;
  color: var(--themeWhite);
  font-style: normal;
  font-weight: bold;
  word-spacing: -0.05em;
  position: relative;
  top: 7px;
}

.pack-select-checkmark {
  position: fixed;
  display: block;
  top: 2px;
  left: 2px;
  z-index: 3;
}

.gray-out {
  background: #43434383;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

@supports (overflow: clip) {
  .cardset-date-tip {
    position: relative;
    overflow: clip;
  }
}

@supports not (overflow: clip) {
  .cardset-date-tip {
    position: relative;
    overflow: hidden; /* This can end up really messing with layouts, but definitely less than not having the fallback */
  }
}

.cardset-date-tip .cardset-date-tip-text {
  background-color: #1E1E1EF3;
  color: var(--themeWhite);
  font-family: var(--textFont);
  font-size: 13px;
  letter-spacing: -0.02em;
  word-spacing: -0.02em;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  border-radius: 0px 0px 4px 4px;
  padding: 7px 0px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(0%, -100%);
  transition: transform .2s;
  margin: 0;
}

.cardset-date-tip:hover .cardset-date-tip-text {
  transform: translate(0%, 0%);
}

@media (max-width: 950px) {
  .packs-container {
    padding: 3px;
    display: block;
    width: auto;
    max-width: calc(99vw - 6px);
  }

  .cardset-box {
    width: 114px;
    height: 210px;
    margin: 1px;
  }
  
  h4 {
    font-size: 16px;
    margin-bottom: 2px;
  }

  .cardset-link {
    height: 154px;
  }

  .cardset-image {
    min-width: 50px;
    max-width: 110px;
  }
  
  .pack-num-entry {
    width: 50px;
    margin-top: 5px;
  }
  
  .pack-num-entry label input {
    width: 20px;
    margin-left: 5px;
  }
  
  .cardset-date-tip .cardset-date-tip-text {
    font-size: 12px;
    padding: 3px 0px;
  }

  .pack-select-count {
    top: 5px;
  }
}