.bar_ad {
  display: block;
  min-width: 200px;
  width: 100%;
  max-width: min(1200px, 95%);
  height: 90px;
  margin: auto;
  margin-top: 15px;
}

.bar_ad_footer {
  display: block;
  min-width: 200px;
  width: 100%;
  max-width: min(1200px, 95%);
  min-height: 50px;
  max-height: 320px;
  margin: auto;
  margin-top: 40px;
}

.left_sidebar_ad_1400 {
  display: block;
  width: 160px;
  min-height: 120px;
  height: 100%;
  max-height: 60vh;
  float: left;
}

.left_sidebar_ad_1400_div {
  display: block;
  position: sticky;
  float: left;
  top: 22vh;
  left: calc(((100% - 1440px) / 4) - 80px);
  width: 160px;
}

.left_sidebar_ad_1200 {
  display: block;
  width: 200px;
  min-height: 120px;
  height: 100%;
  max-height: 60vh;
  float: left;
}

.left_sidebar_ad_1200_div {
  display: block;
  position: sticky;
  float: left;
  top: 22vh;
  left: calc(((100% - 1240px) / 4) - 100px);
  width: 200px;
}

.right_sidebar_ad_1400 {
  display: block;
  width: 160px;
  min-height: 120px;
  height: 100%;
  max-height: 60vh;
  float: right;
}

.right_sidebar_ad_1400_div {
  display: block;
  position: sticky;
  float: right;
  top: 22vh;
  right: calc(((100% - 1440px) / 4) - 80px);
  width: 160px;
}

.right_sidebar_ad_1200 {
  display: block;
  width: 200px;
  min-height: 120px;
  height: 100%;
  max-height: 60vh;
  float: right;
}

.right_sidebar_ad_1200_div {
  display: block;
  position: sticky;
  float: right;
  top: 22vh;
  right: calc(((100% - 1240px) / 4) - 100px);
  width: 200px;
}

@media (max-width: 1790px) {
  .left_sidebar_ad_1400 {
    width: 120px;
  }
  
  .left_sidebar_ad_1400_div {
    left: calc(((100% - 1440px) / 4) - 60px);
    width: 120px;
  }
  
  .right_sidebar_ad_1400 {
    width: 120px;
  }
  
  .right_sidebar_ad_1400_div {
    right: calc(((100% - 1440px) / 4) - 60px);
    width: 120px;
  }
}

@media (max-width: 1700px) {
  .left_sidebar_ad_1400 {
    display: none !important;
  }

  /* .left_sidebar_ad_1400_div {
    display: none !important;
  } */
  
  .left_sidebar_ad_1200 {
    width: 120px;
  }
  
  .left_sidebar_ad_1200_div {
    left: calc(((100% - 1240px) / 4) - 60px);
    width: 120px;
  }

  .right_sidebar_ad_1400 {
    display: none !important;
  }
  
  /* .right_sidebar_ad_1400_div {
    display: none !important;
  } */
  
  .right_sidebar_ad_1200 {
    width: 120px;
  }
  
  .right_sidebar_ad_1200_div {
    right: calc(((100% - 1240px) / 4) - 60px);
    width: 120px;
  }
}

@media (max-width: 1510px) {
  .left_sidebar_ad_1200 {
    display: none !important;
  }

  /* .left_sidebar_ad_1200_div {
    display: none !important;
  } */

  .right_sidebar_ad_1200 {
    display: none !important;
  }
  
  /* .right_sidebar_ad_1200_div {
    display: none !important;
  } */
}

@media (max-width: 1300px) {
  .bar_ad {
    min-width: 200px;
    width: 100%;
    max-width: 100%;
    height: 70px;
    margin-top: 10px;
  }
  
  .bar_ad_footer {
    margin-top: 30px;
  }
}

@media (max-width: 950px) {
  .bar_ad {
    min-width: 200px;
    width: 100%;
    max-width: 100%;
    height: 60px;
    margin-top: 5px;
  }
}
