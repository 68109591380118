.builder-wrapper {
  display: grid;
  grid-template-columns: 7fr 3fr;
  justify-items: center;
  margin: auto;
  max-width: min(1400px, calc(99vw - 50px));
  height: fit-content; 
  vertical-align: middle;
}

.builder-left {
  margin-right: auto;
  width: 100%;
}

.builder-right {
  margin-left: auto;
  width: 95%;
}

.sticky-panel {
  position: sticky;
  top: 10px;
  height: fit-content;
}

.builder-listbox {
  width: 100%;
  padding: 15px 0px 15px 0px;
  min-height: 100px;
}

.builder-listbox-minimized {
  width: 100%;
  padding: 5px 0px 0px 0px;
  min-height: 0px;
}

.maindeck-box {
  background: #D9C6E7;
  border: 1.5px solid #650075;
  margin-bottom: 2px;
}

.dark-theme .maindeck-box {
  background: #AD97BA;
  border: 1.5px solid #650075;
}

.extradeck-box {
  background: #C6E3E7;
  border: 1.5px solid #006E75;
  margin-bottom: 2px;
}

.dark-theme .extradeck-box {
  background: #97B6BA;
  border: 1.5px solid #006E75;
}

.sidedeck-box {
  background: #E7D6C6;
  border: 1.5px solid #754600;
  margin-bottom: 2px;
}

.dark-theme .sidedeck-box {
  background: #BAA897;
  border: 1.5px solid #754600;
}

.forbidden-box {
  background: #F9D6D6;
  border: 1.5px solid #AD2424;
  margin-bottom: 5px;
}

.dark-theme .forbidden-box {
  background: #D4A0A0;
  border: 1.5px solid #AD2424;
}

.limited-box {
  background: #FFEAD5;
  border: 1.5px solid #A76D41;
  margin-bottom: 5px;
}

.dark-theme .limited-box {
  background: #D6BA9E;
  border: 1.5px solid #A76D41;
}

.semilimited-box {
  background: #FFFFD6;
  border: 1.5px solid #95951A;
  margin-bottom: 5px;
}

.dark-theme .semilimited-box {
  background: #D6D69E;
  border: 1.5px solid #95951A;
}

.unlimited-box {
  background: #EBFFD6;
  border: 1.5px solid #58951A;
  margin-bottom: 5px;
}

.dark-theme .unlimited-box {
  background: #BBD69E;
  border: 1.5px solid #58951A;
}

.dropzone-inner {
  display: grid;
  max-width: 100%;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;
  text-align: left;
}

.dropzone-arrow {
  vertical-align: middle;
  float: right;
  filter: invert(12%) sepia(0%) saturate(0%) hue-rotate(267deg) brightness(98%) contrast(100%);
  cursor: pointer;
}

.dark-theme .dropzone-arrow {
  filter: invert(100%) brightness(95%);
}

.dropzone-arrow:hover {
  filter: invert(21%) sepia(81%) saturate(3528%) hue-rotate(346deg) brightness(91%) contrast(104%);
}

.drop-card div {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.drag-preview {
  opacity: .4;
}

.drop-card-trigger {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  margin: 0px;
}

.drop-zone-controls {
  display: grid;
  grid-template-columns: 4fr repeat(1, auto) 6fr;
  justify-items: center;
  margin: auto;
  max-width: 1300px;
  height: fit-content; 
  align-items: center;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 7px;
}

.drop-zone-controls.banlist-builder-controls {
  grid-template-columns: 6fr repeat(1, auto) 6fr;
}

.hanging-controls {
  width: 100%;
  text-align: right;
}

.filters-box {
  position: fixed;
  top: 20%;
  right: 0px;
  margin-top: auto;
  margin-bottom: auto;
  background: var(--pullboxColor);
  border: 1.5px solid var(--pullboxBorderColor);
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  width: 250px;
  align-items: center;
  text-align: center;
  padding: 3px 7px 2px 7px;
  max-height: max-content;
  transition: right .1s linear;
  z-index: 6;
}

.filter-field {
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}

.filter-range {
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  vertical-align: middle;
  align-items: center;
}

.middle-margin {
  margin-left: 7px;
  margin-right: 7px;
  padding-bottom: 5px;
  vertical-align: middle;
  color: var(--mainFontColor);
}

.deckbuilder-controls {
  position: sticky;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  margin: auto;
  max-width: 1400px;
  height: fit-content; 
  vertical-align: middle;
  z-index: 2;
}

.builder-search-card {
  display: flex;
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  text-align: left;
}

.builder-search-card-right {
  display: inline-block;
  max-width: 100%;
  padding-right: 5px;
  overflow-x: hidden;
  max-width: 100%;
  vertical-align: top;
}

.builder-search-card-right > h3 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 0;
  margin: 3px 0px 2px 0px;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: block;
}

.builder-search-card-right > span {
  font-family: var(--textFont);
  font-size: 15px;
  letter-spacing: -0.03em;
  color: var(--mainFontColor);
  margin-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: block;
}

.builder-search-card-left {
  display: inline-block;
}

.close-button {
  filter: brightness(0%);
}

.dark-theme .close-button {
  filter: brightness(95%);
}

.close-button:hover {
  filter: brightness(0) saturate(100%) invert(20%) sepia(85%) saturate(3331%) hue-rotate(346deg) brightness(92%) contrast(102%);
}

.right-controls {
  display: inline;
}

.small-screen-builder-results-box {
  height: 790px;
  width: 390px;
  padding: 5px 2px 5px 5px;
  margin: 0px 0px 5px 0px;
}

.left-tab {
  position: absolute;
  top: 5px;
  right: -34px;
  border-right: none;
  border-radius: 0px 5px 5px 0px;
  background-color: var(--accentColor);
  padding: 7px;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 25%);
}

.dark-theme .black-button.icon-button.left-tab {
  background: var(--accentColor);
  border-left: 1.5px solid var(--pullboxBorderColor);
}

.right-tab {
  position: absolute;
  top: 5px;
  left: -34px;
  border-left: none;
  border-radius: 5px 0px 0px 5px;
  background-color: var(--accentColor);
  padding: 7px;
  box-shadow: -2px 2px 4px rgb(0 0 0 / 25%);
}

.dark-theme .black-button.icon-button.right-tab {
  background: var(--accentColor);
  border-right: 1.5px solid var(--pullboxBorderColor);
}

/* button.left-tab:hover:enabled {
  background-color: var(--accentColorHighlight);
} */

.card-added-popup {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: #1E1E1EF6;
  padding: 10px;
  border-radius: 4px;
  visibility: hidden;
  z-index: 9002;
  width: 200px;
}

.card-added-popup span {
  color: var(--themeWhite);
  font-family: var(--textFont);
  font-size: 13px;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
}

.group-management-controls {
  text-align: left;
}

.group-management-results {
  margin-top: 10px;
  margin-bottom: 5px;
  overflow-y: auto;
  max-height: 60vh;
}

.group-management-results > * {
  background-color: var(--contentBoxSubboxColor);
  margin-bottom: 7px;
}

.group-header {
  background-color: var(--contentBoxSubboxHeaderColor);
  display: grid;
  grid-template-columns: minmax(60px, 1fr) 300px;
  text-align: left;
  padding: 5px 7px 5px 7px;
}

.group-header > * {
  width: 100%;
  align-items: center;
  align-self: center;
}

.group-header > * > * {
  vertical-align: middle;
}

.group-header h3 {
  display: inline-block;
  margin: 0px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  font-size: 20px;
  padding: 0;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
}


.group-search-item {
  padding: 7px;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: move;
}

.group-search-item h4 {
  font-size: 16px;
  font-family: var(--textFont);
  color: var(--mainFontColor);
  font-weight: 500;
  text-align: left;
  padding: 0;
  margin: 0;
  letter-spacing: -0.03em;
  word-spacing: -0.03em;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.grouped-item {
  margin-left: 20px;
}

.group-arrow {
  vertical-align: middle;
  filter: invert(12%) sepia(0%) saturate(0%) hue-rotate(267deg) brightness(98%) contrast(100%);
  cursor: pointer;
}

.dark-theme .group-arrow {
  filter: invert(100%) brightness(95%);
}

.group-arrow:hover {
  filter: invert(21%) sepia(81%) saturate(3528%) hue-rotate(346deg) brightness(91%) contrast(104%);
}

@media (max-width: 1300px) {
  .builder-right {
    margin-left: auto;
    width: 98%;
  }

  .builder-wrapper {
    max-width: calc(99vw - 30px);
  }

  div.deckbuilder-controls .grid-right {
    text-align: right;
  }

  .drop-zone-controls {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    align-items: center;
    text-align: left;
    vertical-align: middle;
    margin-bottom: 5px;
  }

  .drop-zone-controls.banlist-builder-controls {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .grid-row2 {
    margin-top: 5px;
    width: 100%;
  }

  .right-controls {
    text-align: right;
    margin-left: auto;
    float: right;
  }
}

@media (max-width: 950px) {
  .filters-box {
    top: 11%;
    right: -266px;
  }

  .small-screen-builder-results .filter-field {
    margin-bottom: 0px;
  }

  .small-screen-builder-results {
    position: fixed;
    top: 10%;
    left: -258px;
    margin-top: auto;
    margin-bottom: auto;
    background: var(--pullboxColor);
    border: 1.5px solid var(--pullboxBorderColor);
    border-left: none;
    border-radius: 0px 5px 5px 0px;
    width: 250px;
    align-items: center;
    text-align: center;
    padding: 3px 3px 3px 3px;
    max-height: max-content;
    transition: left .1s linear;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 25%);
    z-index: 5;
  }

  .small-screen-builder-results-box {
    height: min(70vh, 790px);
    width: 250px;
    padding: 0px 2px 0px 2px;
    margin: 5px 5px 5px 0px;
  }
  
  .builder-search-card-right > h3 {
    font-size: 14px;
    text-align: left;
  }

  .builder-search-card-right > span {
    font-size: 13px;
  }

  .builder-wrapper {
    grid-template-columns: 1fr;
    max-width: calc(99vw - 20px);
  }

  .builder-listbox {
    padding: 5px 0px 5px 0px;
    max-width: 100%;
  }
  
  .builder-listbox-minimized {
    padding: 2px 0px 0px 0px;
    max-width: 100%;
  }

  .right-controls {
    text-align: left;
    margin-left: 0px;
    float: none;
  }

  .drop-zone-controls {
    grid-template-rows: 1fr 2fr;
  }

  .builder-listbox .card-image {
    height: auto;
  }

  .small-screen-builder-results-box .card-image {
    height: auto;
  }
  
  .group-header {
    grid-template-columns: 100%;
    grid-template-rows: 2fr;
  }
}