
footer {
  margin-top: 25px;
  background: var(--darkBoxColor);
  width: 100%;
  bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

footer u {
  font-family: var(--textFont);
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #F1F1F1;
  margin: auto;
  width: fit-content;
  text-decoration: underline !important;
  display: block;
  cursor: pointer;
}

footer u:hover {
  color: var(--accentColor);
}

footer p {
  font-family: var(--textFont);
  font-style: italic;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  color: #848484;
  margin: auto;
  margin-top: 3px;
  padding: 0;
  max-width: 850px;
}

footer span {
  display: block;
  font-family: var(--textFont);
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  color: #d1d1d1;
  margin: auto;
  margin-top: 2px;
  padding: 0;
  max-width: 850px;
}

footer a {
  font-family: var(--textFont);
  font-style: normal;
  text-decoration: underline;
  text-decoration-line: underline;
  font-weight: 600;
  font-size: 11px;
  text-align: center;
  color: #d1d1d1;
}

footer a:link {
  text-decoration: underline;
}

footer a:visited {
  text-decoration: underline;
}

footer a:hover {
  text-decoration: underline;
}

footer a:active {
  text-decoration: underline;
}

footer a:hover {
  color: var(--accentColor);
}

.footer img {
  filter: brightness(95%);
}

@media (max-width: 950px) {
  footer u {
    font-size: 12px;
    padding-bottom: 3px;
  }
  footer p {
    font-size: 9px;
  }

  footer span {
    font-size: 10px;
  }

  footer a {
    font-size: 11px;
  }
}